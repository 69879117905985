
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import SporteventResult from "../../models/sportevent/SporteventResult";
import SporteventCompetition from "../../models/sportevent/SporteventCompetition";
import PrintSettingsService from "../../services/helper/PrintSettingsService";
import Sportevent from "../../models/sportevent/Sportevent";
import SporteventService from "../../services/sportevent/SporteventService";
import moment from "moment";
import Discipline from "@/models/sporteventCalculation/Discipline";

@Component({})
export default class ListResultsSingleCertificateComponent extends Vue {
  public loading = true;
  public hide = true;

  @Prop({ required: true })
  public sporteventCompetition!: SporteventCompetition;

  @Prop({ required: true })
  public allSporteventResult!: SporteventResult[];

  @Prop({ default: false })
  public sortRankReverse!: boolean;

  @Prop({ default: true })
  public certificateType!: string;

  @Prop({ default: false })
  public isCommonList!: boolean;

  public currentResults: SporteventResult[] = [];

  public sportevent: Sportevent = new Sportevent();

  @Watch("sortRankReverse")
  onPropertyChanged(sortReverse: boolean) {
    if (sortReverse) {
      this.currentResults.sort((a, b) => {
        if (a.totalPoints < b.totalPoints) return -1;
        if (a.totalPoints > b.totalPoints) return 1;
        return 0;
      });
    } else {
      this.currentResults.sort((a, b) => {
        if (a.totalPoints < b.totalPoints) return 1;
        if (a.totalPoints > b.totalPoints) return -1;
        return 0;
      });
    }
  }

  async mounted(): Promise<void> {
    this.sportevent = SporteventService.get();

    if (this.isCommonList) {
      this.currentResults = this.allSporteventResult;
    } else {
      this.currentResults = this.allSporteventResult.filter(
        (r: SporteventResult) =>
          r.sporteventCompetition.id === this.sporteventCompetition.id &&
          r.disciplineResults.find( dr => dr.usedAValue > 0) !== undefined
      );
    }
    this.onPropertyChanged(false);

    let rank = 0;
    this.currentResults.forEach((r: SporteventResult): void => {
      rank++;
      r.rank = rank;
      if (r.totalPoints > 0) {
        this.hide = false;
        return;
      }
    });
    this.onPropertyChanged(this.sortRankReverse);
    PrintSettingsService.setHeader(true);
    this.loading = false;
  }

  getDate(): string {
    let date = moment
      .utc(this.$store.state.localstore_sportevent.sportevent.eventDate)
      .format("DD.MM.YYYY");
    //console.log("converted date", date);
    return date;
  }

  getUnitName(id?: string): string {
    let unitname = this.sporteventCompetition.disciplines.find( (d => d.id === id))?.unit.shortname;
    if( unitname !== undefined ){
      return unitname;
    }
    return "";
  }
}
