var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (!_vm.hide)?_c('div',[_c('h3',[_vm._v(_vm._s(_vm.sporteventCompetition.competition.longName))]),_c('table',[_c('thead',[_c('tr',[_c('th',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t("sportevent.rank")))]),_c('th',{staticClass:"starter-name"},[_vm._v(" "+_vm._s(_vm.$t("labels.name"))+" ")]),_c('th',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t("labels.points"))+" ")]),_vm._l((_vm.sporteventCompetition.disciplines),function(discipline){return _c('th',{key:discipline.id,staticClass:"text-center disciplinecol"},[_c('span',[_vm._v(_vm._s(discipline.name))])])})],2)]),(!_vm.loading)?_c('tbody',_vm._l((_vm.currentResults),function(currentResult,index){return _c('tr',{key:currentResult.id},[_c('td',{staticClass:"rank text-center"},[(
              _vm.currentResults[index - 1] === undefined ||
              currentResult.totalPoints !==
                _vm.currentResults[index - 1].totalPoints
            )?_c('span',[_vm._v(" "+_vm._s(currentResult.rank)+". "+_vm._s(_vm.setCurrentRanking(currentResult.rank))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.getCurrentRanking())+". ")])]),_c('td',{staticClass:"starter-name"},[_c('span',{staticClass:"name"},[_vm._v(" "+_vm._s(currentResult.starter.person.firstname)+" "+_vm._s(currentResult.starter.person.lastname)+" ")]),_c('br'),_vm._v(" "+_vm._s(currentResult.starter.startsForSociety.shortname)+" ")]),_c('td',{staticClass:"total-points text-center"},[_vm._v(" "+_vm._s(currentResult.totalPoints.toLocaleString())+" ")]),_vm._l((_vm.sporteventCompetition.disciplines),function(discipline){return _c('td',{key:discipline.id,class:`text-center disciplinecol disciplinecol${_vm.sporteventCompetition.disciplines.length}`},_vm._l((currentResult.disciplineResults),function(disciplineResult){return _c('span',{key:disciplineResult.id},[(
                discipline.id === disciplineResult.discipline.id &&
                (disciplineResult.calculatedPoints > 0 || ( disciplineResult.usedAValue !== undefined && disciplineResult.usedAValue > 0))
              )?_c('span',[_c('strong',[_vm._v(" "+_vm._s(disciplineResult.calculatedPoints.toLocaleString())+" ")]),_c('br'),(discipline.hasBPoints)?_c('span',[_vm._v(" A:"+_vm._s(disciplineResult.usedAValue.toLocaleString())+" B:"+_vm._s(disciplineResult.calculatedBPoints.toLocaleString())+" ")]):_c('span',[_vm._v(" "+_vm._s(disciplineResult.usedAValue.toLocaleString())+" "+_vm._s(discipline.unit.shortname.toLocaleString())+" ")]),(disciplineResult.usedPenaltyPoints > 0)?_c('span',[_vm._v(" S:"+_vm._s(disciplineResult.usedPenaltyPoints.toLocaleString())+" ")]):_vm._e()]):_vm._e()])}),0)})],2)}),0):_vm._e()]),_c('br')]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }