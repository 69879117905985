
import { Component, Vue, Watch } from "vue-property-decorator";
import moment from "moment";

import formatDate from "../../plugins/formatDate";
import SporteventCompetition from "../../models/sportevent/SporteventCompetition";

import Starter from "../../models/sportevent/Starter";
import SporteventResultService from "../../services/sportevent/SporteventResultService";
import SporteventResult from "../../models/sportevent/SporteventResult";
import StarterService from "../../services/sportevent/StarterService";
import ListResultsSingleTableComponent from "./ListResultsSingleTable.vue";
import ListResultsSingleCertificateComponent from "./ListResultsSingleCertificate.vue";
import SporteventService from "../../services/sportevent/SporteventService";
import StartBlock from "../../models/sportevent/StartBlock";
import StartBlockService from "../../services/sportevent/StartBlockService";
import StartGroupService from "../../services/sportevent/StartGroupService";
import StartGroup from "../../models/sportevent/StartGroup";
import Discipline from "../../models/sporteventCalculation/Discipline";

@Component({
  components: {
    ListResultsSingleTableComponent,
    ListResultsSingleCertificateComponent,
  },
  filters: {
    formatDate,
  },
})
export default class ListResultsSingleComponent extends Vue {
  public loading = true;
  public changeView = false;
  public sporteventDate = "";
  public allStarters: Starter[] = [];
  public allSporteventCompetition: SporteventCompetition[] = [];
  public allSporteventResult: SporteventResult[] = [];
  public filteredSporteventResult: SporteventResult[] = [];
  public allStartBlocks: StartBlock[] = [];

  public allStartGroups: StartGroup[] = [];

  public sortOptions = [
    { item: "A", name: "Nach Altersklassen" },
    { item: "B", name: "Nach Altersklassen (Ältere zuerst)" },
    { item: "C", name: "Nach Altersklassen (Unabhängig des Bewerbes)" },
    { item: "D", name: "Nach Altersklassen (Unabhängig des Bewerbes - Ältere zuerst)" },
  ];

  public selectedSortOption = "A";

  public sortOptionsRank = [
    { item: false, name: "Ersten Rang zuerst" },
    { item: true, name: "Letzten Rang zuerst" },
  ];
  public selectedSortOptionRank = false;

  public viewOptions = [
    { item: "A", name: "Ergebnisliste" },
    { item: "B", name: "Urkunden mit Datum & Veranstaltungsnamen" },
    { item: "C", name: "Urkunden ohne Datum & Veranstaltungsnamen" },
    { item: "D", name: "Urkunden Liesing" },
  ];
  
  public selectedViewOptions = "A";

  public viewStartBlockId = "0";

  public pageOrientationOptions = [
    { item: "a4portrait", name: "A4 Hochformat" },
    { item: "a4landscape", name: "A4 Querformat" },
  ];

  public selectedPageOrientation = "a4portrait";

  public isCommonList = false;

  @Watch("$route", { immediate: true, deep: true })
  onUrlChange(): void {
    if (this.$route.query.t === "common") {
      this.isCommonList = true;
    } else {
      this.isCommonList = false;
    }

    this.getAll();
  }

  async getAll(): Promise<void> {
    const sportevent = SporteventService.get();
    if (sportevent.id != undefined) {
      this.loading = true;
      this.allSporteventResult =
        await SporteventResultService.getAllBySportevent(sportevent.id);
      if (
        this.allSporteventResult != null &&
        this.allSporteventResult != undefined
      ) {
        this.allSporteventCompetition =
          await StarterService.getAllSporteventCompetitions();
        this.sortbyAge();
      }

      this.allStartBlocks = await StartBlockService.getAllBySportevent(
        sportevent.id
      );
      let showallStartblocks = new StartBlock();
      showallStartblocks.id = "0";
      showallStartblocks.name = "Alle anzeigen";
      showallStartblocks.startBlockPosition = 0;
      this.allStartBlocks.push(showallStartblocks);
      this.allStartBlocks.sort((a, b) =>
        a.startBlockPosition > b.startBlockPosition
          ? 1
          : b.startBlockPosition > a.startBlockPosition
          ? -1
          : 0
      );

      console.log("allStartGroups", this.allStartGroups);
    }
    this.filteredSporteventResult = this.allSporteventResult;

    if (this.isCommonList) {
      let commonCompetiton = new SporteventCompetition();
      commonCompetiton.id = "common";
      commonCompetiton.competition.name = sportevent.name;
      this.allSporteventCompetition.forEach((sc: SporteventCompetition) => {
        sc.disciplines.forEach((d: Discipline) => {
          if (
            commonCompetiton.disciplines.findIndex(
              (findD) => findD.id === d.id
            ) < 0
          ) {
            commonCompetiton.disciplines.push(d);
          }
        });
      });
      this.allSporteventCompetition = [];
      this.allSporteventCompetition.push(commonCompetiton);
    }

    //console.log(this.allSporteventResult);
    this.sporteventDate = moment(
      this.$store.state.localstore_sportevent.sportevent.eventDate
    ).format("DD. MMM. YYYY");

    this.setPageOrientationStyles();
    this.loading = false;
  }

  async filterSporteventResultsByStartBlock(): Promise<void> {
    this.loading = true;
    this.allStartGroups = await StartGroupService.getAllBySportevent();
    if (this.viewStartBlockId === "0") {
      console.log("alle anzeigen");
      this.filteredSporteventResult = this.allSporteventResult;
    } else {
      if (this.allStartGroups.length === 0) {
        this.allStartGroups = await StartGroupService.getAllBySportevent(true);
      }

      let starters: Starter[] = [];
      const filteredStartgroups = this.allStartGroups.filter(
        (s: StartGroup) => s.startBlock.id === this.viewStartBlockId
      );
      filteredStartgroups.forEach((s: StartGroup) => {
        starters = starters.concat(s.starters);
      });

      this.filteredSporteventResult = this.allSporteventResult.filter(
        (r: SporteventResult) =>
          starters.find((s: Starter) => s.id == r.starter.id)
      );
    }
    //console.log("filteredSporteventResult", this.filteredSporteventResult);
    this.loading = false;
  }

  updateSort(): void {
    this.loading = true;
    console.log("update sort");
    if (this.selectedSortOption === "A") {
      this.sortbyAge();
    } else if (this.selectedSortOption === "B") {
      this.sortbyAge(true);
    } else if (this.selectedSortOption === "C") {
      this.sortbyAgeBeforeCompetition();
    } else if (this.selectedSortOption === "D") {
      this.sortbyAgeBeforeCompetition(true);
    }
    this.loading = false;
  }

  sortbyAgeBeforeCompetition(agereverse = false): void {
    this.allSporteventCompetition = this.allSporteventCompetition.sort(
      (a, b) => {
        if (
          a.competition.ageGenderGroup.ageTo <
          b.competition.ageGenderGroup.ageTo
        )
          return agereverse ? 1 : -1;
        if (
          a.competition.ageGenderGroup.ageTo >
          b.competition.ageGenderGroup.ageTo
        )
          return agereverse ? -1 : 1;
        if (
          a.competition.ageGenderGroup.gender <
          b.competition.ageGenderGroup.gender
        )
          return -1;
        if (
          a.competition.ageGenderGroup.gender >
          b.competition.ageGenderGroup.gender
        )
          return 1;
        if (
          a.competition.name.substring(0, 2) <
          b.competition.name.substring(0, 2)
        )
          return -1;
        if (
          a.competition.name.substring(0, 2) >
          b.competition.name.substring(0, 2)
        )
          return 1;
        return 0;
      }
    );
  }

  sortbyAge(agereverse = false): void {
    this.allSporteventCompetition = this.allSporteventCompetition.sort(
      (a, b) => {
        if (
          a.competition.name.substring(0, 2) <
          b.competition.name.substring(0, 2)
        )
          return -1;
        if (
          a.competition.name.substring(0, 2) >
          b.competition.name.substring(0, 2)
        )
          return 1;
        if (
          a.competition.ageGenderGroup.ageTo <
          b.competition.ageGenderGroup.ageTo
        )
          return agereverse ? 1 : -1;
        if (
          a.competition.ageGenderGroup.ageTo >
          b.competition.ageGenderGroup.ageTo
        )
          return agereverse ? -1 : 1;
        if (
          a.competition.ageGenderGroup.gender <
          b.competition.ageGenderGroup.gender
        )
          return -1;
        if (
          a.competition.ageGenderGroup.gender >
          b.competition.ageGenderGroup.gender
        )
          return 1;
        return 0;
      }
    );
  }

  setPageOrientationStyles(): void {
    console.log(`call enablePageOrientationStyles ${this.selectedPageOrientation}`);
    const printStyle = document.getElementById('selectedPageOrientation');
    if (printStyle) {
      printStyle.remove();
    }
    if (!document.getElementById('selectedPageOrientation')) {
      console.log("add element");
      const style = document.createElement('style');
      style.setAttribute('id', 'selectedPageOrientation');
      style.setAttribute('media', 'print');

      if (this.selectedPageOrientation === "a4portrait") {
        style.textContent = `@page { size: A4 portrait; margin: 0; }`;
      } else if (this.selectedPageOrientation === "a4landscape") {
        style.textContent = `@page { size: A4 landscape; margin: 0; }`;
      } else if (this.selectedPageOrientation === "a4landscape-right") {
        style.textContent = `@page { size: A4 landscape; margin: 0; }`;
      }
      document.head.appendChild(style);
      console.log("element append");
    } else {
      console.log("element already exists");
    }
  }
}
