import HttpService from "../HttpService";
import SporteventResult from "@/models/sportevent/SporteventResult";
import Starter from "@/models/sportevent/Starter";
import StarterService from "./StarterService";

class SporteventResultService {
  public serviceUrl = "sportevent/result/";

  async getAllBySportevent(sporteventId: string): Promise<SporteventResult[]> {
    const response = await HttpService.get<SporteventResult[]>(
      `${this.serviceUrl}${sporteventId}/`
    );
    if (response != undefined) {
      const sporteventResults = response.map(
        (sr: SporteventResult) => new SporteventResult(sr)
      );
      const allStarters = await StarterService.getAll();
      for (let i = 0; i < sporteventResults.length; i++) {
        const starter = allStarters.find(
          (s: Starter) => s.id === sporteventResults[i].starter.id
        );
        if (starter !== undefined) {
          sporteventResults[i].starter = starter;
        }
      }

      return sporteventResults;
    }
    return [];
  }
}

export default new SporteventResultService();
