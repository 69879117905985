import Starter from "./Starter";
import SporteventCompetition from "./SporteventCompetition";
import DisciplineResult from "./DisciplineResult";

export default class SporteventResult {
  id?: string;
  starter: Starter = new Starter();
  sporteventCompetition: SporteventCompetition = new SporteventCompetition();
  disciplineResults: DisciplineResult[] = [];
  totalPoints = 0;
  rank = 0;

  constructor(sporteventResult?: SporteventResult) {
    if (sporteventResult === undefined || sporteventResult === null) {
      return;
    }
    this.id = sporteventResult.id;
    this.starter = sporteventResult.starter;
    this.sporteventCompetition = sporteventResult.sporteventCompetition;
    this.disciplineResults = sporteventResult.disciplineResults;
    this.totalPoints = sporteventResult.totalPoints;
    this.rank = sporteventResult.rank;
  }
}
